import React from 'react';
import PropTypes from 'prop-types';
import AnchorLink from 'react-anchor-link-smooth-scroll';

export const LocalNav = ({ title, links, navId, showPageTitle }) => (
  <nav className="local-nav container" role="navigation">
    {showPageTitle && title && (
      <span className="local-nav__title">{title}</span>
    )}
    <ul className="local-nav__list">
      {links.map(link => (
        <li className="local-nav__item" key={link.anchor}>
          <AnchorLink
            className={`local-nav__link ${
              link.anchor === navId ? 'is-active' : ''
            }`}
            href={`#${link.anchor}`}
            offset="77"
          >
            {link.linkText}
          </AnchorLink>
        </li>
      ))}
    </ul>
  </nav>
);

LocalNav.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      anchor: PropTypes.string.isRequired,
      linkText: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string,
  navId: PropTypes.string.isRequired,
  showPageTitle: PropTypes.bool,
};

LocalNav.defaultProps = {
  showPageTitle: false,
  title: '',
};
