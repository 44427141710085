import React from 'react';
import PropTypes from 'prop-types';

import { Image } from './patterns/image';
import { TransitionImage } from './transitionImage';
import { Video } from './patterns/video';

export const ImagesBlock = ({ bgColor, id, images }) => (
  <section
    id={id}
    className="case-study__images-block"
    style={{ backgroundColor: bgColor }}
  >
    {images.map(image =>
      image.imageFields.map((imageField, index) => {
        const typeName = imageField.__typename; // eslint-disable-line no-underscore-dangle

        switch (typeName) {
          case 'NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_ImagesBlock_images_ImageFields_StaticImage':
            return (
              <Image
                key={imageField.imageSmall.sourceUrl}
                alt={imageField.altText}
                srcSmall={imageField.imageSmall.sourceUrl}
                srcSmall2x={
                  imageField.imageSmall2x && imageField.imageSmall2x.sourceUrl
                }
                srcLarge={imageField.imageLarge.sourceUrl}
                srcLarge2x={
                  imageField.imageLarge2x && imageField.imageLarge2x.sourceUrl
                }
                lazyLoad
              />
            );

          case 'NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_ImagesBlock_images_ImageFields_TransitionImage':
            return (
              <TransitionImage
                key={imageField.image1Small.sourceUrl}
                altText={imageField.altText}
                index={index}
                src1Small={imageField.image1Small.sourceUrl}
                src1Small2x={
                  imageField.image1Small2x && imageField.image1Small2x.sourceUrl
                }
                src1Large={imageField.image1Large.sourceUrl}
                src1Large2x={
                  imageField.image1Large2x && imageField.image1Large2x.sourceUrl
                }
                src2Large={imageField.image2Large.sourceUrl}
                src2Large2x={
                  imageField.image2Large2x && imageField.image2Large2x.sourceUrl
                }
              />
            );

          case 'NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_ImagesBlock_images_ImageFields_Video':
            return (
              <Video
                videoUrl={imageField.video.mediaItemUrl}
                videoFallbackUrl={imageField.videoFallback.mediaItemUrl}
                key={imageField.video.mediaItemUrl}
              />
            );

          default:
            return null;
        }
      })
    )}
  </section>
);

ImagesBlock.propTypes = {
  bgColor: PropTypes.string,
  id: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

ImagesBlock.defaultProps = {
  bgColor: '',
};
