import React from 'react';
import PropTypes from 'prop-types';

import { Image } from './patterns/image';
import { TransitionImage } from './transitionImage';
import { Video } from './patterns/video';

export const ItemHighlight = ({
  anchor,
  backgroundColor,
  fields,
  hasDropShadow,
  padded,
}) => {
  const hiddenOnMobile = fields.map(field => field.hideOnMobile)[0];

  return (
    <section
      id={anchor}
      className={`case-study__item-highlight ${
        hiddenOnMobile ? 'large-screen-only' : ''
      } ${padded ? 'padded' : ''} ${hasDropShadow ? 'has-drop-shadow' : ''}`}
      style={{ backgroundColor }}
    >
      <div className="container">
        {fields.map(field => {
          const typeName = field.__typename; // eslint-disable-line no-underscore-dangle

          switch (typeName) {
            case 'NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_ItemHighlight_ItemHighlightFields_LargeImage':
              return (
                <Image
                  alt={field.altText}
                  srcSmall={field.imageSmall.sourceUrl}
                  srcSmall2x={
                    field.imageSmall2x && field.imageSmall2x.sourceUrl
                  }
                  srcLarge={field.imageLarge.sourceUrl}
                  srcLarge2x={
                    field.imageLarge2x && field.imageLarge2x.sourceUrl
                  }
                  lazyLoad
                  key={field.imageSmall.sourceUrl}
                />
              );

            case 'NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_ItemHighlight_ItemHighlightFields_SmallImage':
              return (
                <Image
                  alt={field.altText}
                  className={field.hideOnMobile ? 'large-screen-only' : ''}
                  srcSmall={field.imageSmall.sourceUrl}
                  srcSmall2x={
                    field.imageSmall2x && field.imageSmall2x.sourceUrl
                  }
                  srcLarge={field.imageLarge.sourceUrl}
                  srcLarge2x={
                    field.imageLarge2x && field.imageLarge2x.sourceUrl
                  }
                  lazyLoad
                  key={field.imageSmall.sourceUrl}
                  isSmall
                />
              );

            case 'NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_ItemHighlight_ItemHighlightFields_TransitionImage':
              return (
                <TransitionImage
                  key={field.image1Small.sourceUrl}
                  altText={field.altText}
                  src1Small={field.image1Small.sourceUrl}
                  src1Small2x={
                    field.image1Small2x && field.image1Small2x.sourceUrl
                  }
                  src1Large={field.image1Large.sourceUrl}
                  src1Large2x={
                    field.image1Large2x && field.image1Large2x.sourceUrl
                  }
                  src2Large={field.image2Large.sourceUrl}
                  src2Large2x={
                    field.image2Large2x && field.image2Large2x.sourceUrl
                  }
                />
              );

            case 'NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_ItemHighlight_ItemHighlightFields_Video':
              return (
                <Video
                  videoUrl={field.video.mediaItemUrl}
                  videoFallbackUrl={
                    field.videoFallback && field.videoFallback.mediaItemUrl
                  }
                  key={field.video.mediaItemUrl}
                />
              );

            case 'NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_ItemHighlight_ItemHighlightFields_TextBlock':
              return (
                <div
                  className={
                    field.hideOnMobile
                      ? 'case-study__item-highlight-text large-screen-only'
                      : 'case-study__item-highlight-text'
                  }
                  key={field.id}
                >
                  {(field.title || field.subtitle) && (
                    <div className="case-study__title">
                      {field.title && <p>{field.title}</p>}
                      {field.subtitle && <p>{field.subtitle}</p>}
                    </div>
                  )}
                  {field.copy &&
                    field.copy.map(paragraph => (
                      <p
                        className="case-study__description"
                        key={paragraph.paragraph}
                      >
                        {paragraph.paragraph}
                      </p>
                    ))}
                  {field.categoriesList && (
                    <p className="case-study__categories">
                      {field.categoriesList}
                    </p>
                  )}
                </div>
              );

            default:
              return null;
          }
        })}
      </div>
    </section>
  );
};

ItemHighlight.propTypes = {
  anchor: PropTypes.string,
  backgroundColor: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hasDropShadow: PropTypes.bool,
  padded: PropTypes.bool,
};

ItemHighlight.defaultProps = {
  anchor: '',
  backgroundColor: '',
  hasDropShadow: false,
  padded: false,
};
