import React from 'react';
import PropTypes from 'prop-types';

import { Image } from './patterns/image';
import { LocalNav } from './patterns/localNav';
import { Video } from './patterns/video';

export const HeroImageBlock = ({
  backgroundColor,
  altText,
  srcSmall,
  srcSmall2x,
  srcLarge,
  srcLarge2x,
  video,
  videoFallback,
  navId,
  navLinks,
  navTitle,
  showPageTitle,
}) => (
  <section
    className="case-study__hero-image"
    style={{ backgroundColor }}
    id={navId}
    key={srcSmall}
  >
    <LocalNav
      links={navLinks}
      title={navTitle}
      navId={navId}
      showPageTitle={showPageTitle}
    />
    {srcSmall && (
      <Image
        alt={altText}
        srcSmall={srcSmall}
        srcSmall2x={srcSmall2x && srcSmall2x}
        srcLarge={srcLarge}
        srcLarge2x={srcLarge2x && srcLarge2x}
      />
    )}
    {video && <Video videoUrl={video} videoFallbackUrl={videoFallback} />}
  </section>
);

HeroImageBlock.propTypes = {
  altText: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  srcSmall: PropTypes.string,
  srcSmall2x: PropTypes.string,
  srcLarge: PropTypes.string,
  srcLarge2x: PropTypes.string,
  navId: PropTypes.string.isRequired,
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      anchor: PropTypes.string,
      linkText: PropTypes.string,
    })
  ).isRequired,
  navTitle: PropTypes.string,
  showPageTitle: PropTypes.bool,
  video: PropTypes.string,
  videoFallback: PropTypes.string,
};

HeroImageBlock.defaultProps = {
  backgroundColor: '',
  navTitle: '',
  srcSmall: '',
  srcSmall2x: '',
  srcLarge: '',
  srcLarge2x: '',
  showPageTitle: false,
  video: '',
  videoFallback: '',
};
