/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

export const TextBlock = ({
  title,
  subtitle,
  copy,
  styledCopy,
  categories,
}) => (
  <section className="case-study__text-block">
    <div className="container">
      <div className="case-study__title">
        {title && <p>{title}</p>}
        {subtitle && <p>{subtitle}</p>}
      </div>
      {copy &&
        copy.map(paragraph => (
          <p key={paragraph.paragraph} className="case-study__description">
            {paragraph.paragraph}
          </p>
        ))}
      {styledCopy &&
        styledCopy.map(paragraph => (
          <p
            key={paragraph.styledParagraph}
            className="case-study__description"
            dangerouslySetInnerHTML={{
              __html: paragraph.styledParagraph,
            }}
          />
        ))}
      {categories && <p className="case-study__categories">{categories}</p>}
    </div>
  </section>
);

TextBlock.propTypes = {
  categories: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  copy: PropTypes.arrayOf(PropTypes.shape({})),
  styledCopy: PropTypes.arrayOf(PropTypes.shape({})),
};

TextBlock.defaultProps = {
  categories: '',
  title: '',
  subtitle: '',
  copy: [],
  styledCopy: [],
};
