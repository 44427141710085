import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Layout } from '../components/layout';
import SEO from '../components/seo';
import { CarouselBlock } from '../components/carouselBlock';
import { HeroImageBlock } from '../components/heroImageBlock';
import { ImagesBlock } from '../components/imagesBlock';
import { ItemHighlight } from '../components/itemHighlight';
import { OnThatNote } from '../components/onThatNote';
import { TextBlock } from '../components/textBlock';

const CaseStudyTemplate = ({ data }) => {
  const pageTitle = data.neueamsterdam.caseStudy.title;
  const sections =
    data.neueamsterdam.caseStudy.caseStudyFields.caseStudySections;

  return (
    <Layout>
      <SEO title={pageTitle} />
      <article className="case-study">
        <h1 className="visuallyhidden">{pageTitle}</h1>
        {sections.map(section => {
          const typeName = section.__typename; // eslint-disable-line no-underscore-dangle

          switch (typeName) {
            case 'NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_HeroImageBlock':
              return (
                <HeroImageBlock
                  key={section.id}
                  backgroundColor={section.backgroundColor2}
                  altText={section.altText}
                  srcSmall={
                    section.heroImageSmall && section.heroImageSmall.sourceUrl
                  }
                  srcSmall2x={
                    section.heroImageSmall2x &&
                    section.heroImageSmall2x.sourceUrl
                  }
                  srcLarge={
                    section.heroImageLarge && section.heroImageLarge.sourceUrl
                  }
                  srcLarge2x={
                    section.heroImageLarge2x &&
                    section.heroImageLarge2x.sourceUrl
                  }
                  video={section.video && section.video.mediaItemUrl}
                  videoFallback={
                    section.videoFallback && section.videoFallback.mediaItemUrl
                  }
                  navId={section.navId}
                  navLinks={section.navLinks}
                  navTitle={pageTitle}
                  showPageTitle={section.showPageTitle}
                />
              );

            case 'NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_CarouselBlock':
              return <CarouselBlock data={section} key={section.id} />;

            case 'NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_ImagesBlock':
              return (
                <ImagesBlock
                  id={section.id}
                  bgColor={section.backgroundColor2}
                  images={section.images}
                  key={section.id}
                />
              );

            case 'NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_ItemHighlight':
              return (
                <ItemHighlight
                  anchor={section.anchorId}
                  backgroundColor={section.backgroundColor}
                  fields={section.itemHighlightFields}
                  hasDropShadow={section.hasDropShadow}
                  padded={section.padded}
                  key={section.id}
                />
              );

            case 'NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_OnThatNote':
              return (
                <OnThatNote
                  anchor={section.anchorId}
                  fields={section.onThatNoteFields}
                  key={section.id}
                />
              );

            case 'NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_TextBlock':
              return (
                <TextBlock
                  key={section.id}
                  title={section.title}
                  subtitle={section.subtitle}
                  copy={section.copy}
                  styledCopy={section.styledCopy}
                  categories={section.categories}
                />
              );

            default:
              return null;
          }
        })}

        <div className="case-study__side-stripe" aria-hidden />
      </article>
    </Layout>
  );
};

CaseStudyTemplate.propTypes = {
  data: PropTypes.shape({
    neueamsterdam: PropTypes.shape({
      caseStudy: PropTypes.shape({
        title: PropTypes.string,
        caseStudyFields: PropTypes.shape({
          caseStudySections: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      }),
    }),
  }).isRequired,
};

export const query = graphql`
  query($id: ID!) {
    neueamsterdam {
      caseStudy(id: $id) {
        title
        caseStudyFields {
          caseStudySections {
            ... on NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_HeroImageBlock {
              id
              backgroundColor2
              altText
              heroImageSmall {
                sourceUrl
              }
              heroImageSmall2x {
                sourceUrl
              }
              heroImageLarge {
                sourceUrl
              }
              heroImageLarge2x {
                sourceUrl
              }
              video {
                mediaItemUrl
              }
              videoFallback {
                mediaItemUrl
              }
              navId
              navLinks {
                anchor
                linkText
              }
              showPageTitle
            }
            ... on NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_CarouselBlock {
              id
              backgroundColor2
              title
              subtitle
              description
              categories
              categoriesPosition
              slides {
                imageSmall {
                  sourceUrl
                }
                imageSmall2x {
                  sourceUrl
                }
                imageLarge {
                  sourceUrl
                }
                imageLarge2x {
                  sourceUrl
                }
                altText
              }
            }
            ... on NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_ImagesBlock {
              id
              backgroundColor2
              images {
                imageFields {
                  ... on NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_ImagesBlock_images_ImageFields_StaticImage {
                    altText
                    imageLarge {
                      sourceUrl
                    }
                    imageLarge2x {
                      sourceUrl
                    }
                    imageSmall {
                      sourceUrl
                    }
                    imageSmall2x {
                      sourceUrl
                    }
                  }
                  ... on NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_ImagesBlock_images_ImageFields_TransitionImage {
                    altText
                    image1Large {
                      sourceUrl
                    }
                    image1Large2x {
                      sourceUrl
                    }
                    image1Small {
                      sourceUrl
                    }
                    image1Small2x {
                      sourceUrl
                    }
                    image2Large {
                      sourceUrl
                    }
                    image2Large2x {
                      sourceUrl
                    }
                  }
                  ... on NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_ImagesBlock_images_ImageFields_Video {
                    video {
                      mediaItemUrl
                    }
                    videoFallback {
                      mediaItemUrl
                    }
                  }
                }
              }
            }
            ... on NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_OnThatNote {
              id
              anchorId
              onThatNoteFields {
                ... on NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_OnThatNote_OnThatNoteFields_TextBlock {
                  id
                  hideOnMobile
                  title
                  copy {
                    paragraph
                  }
                }
                ... on NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_OnThatNote_OnThatNoteFields_Image {
                  altText
                  imageLarge {
                    sourceUrl
                  }
                  imageLarge2x {
                    sourceUrl
                  }
                  imageSmall {
                    sourceUrl
                  }
                  imageSmall2x {
                    sourceUrl
                  }
                }
              }
            }
            ... on NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_ItemHighlight {
              id
              anchorId
              backgroundColor
              padded
              hasDropShadow
              itemHighlightFields {
                ... on NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_ItemHighlight_ItemHighlightFields_LargeImage {
                  imageSmall {
                    sourceUrl
                  }
                  imageSmall2x {
                    sourceUrl
                  }
                  imageLarge {
                    sourceUrl
                  }
                  imageLarge2x {
                    sourceUrl
                  }
                  altText
                }
                ... on NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_ItemHighlight_ItemHighlightFields_TextBlock {
                  id
                  title
                  subtitle
                  copy {
                    paragraph
                  }
                  categoriesList
                  hideOnMobile
                }
                ... on NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_ItemHighlight_ItemHighlightFields_SmallImage {
                  imageSmall {
                    sourceUrl
                  }
                  imageSmall2x {
                    sourceUrl
                  }
                  imageLarge {
                    sourceUrl
                  }
                  imageLarge2x {
                    sourceUrl
                  }
                  altText
                  hideOnMobile
                }
                ... on NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_ItemHighlight_ItemHighlightFields_TransitionImage {
                  altText
                  image1Large {
                    sourceUrl
                  }
                  image1Large2x {
                    sourceUrl
                  }
                  image1Small {
                    sourceUrl
                  }
                  image1Small2x {
                    sourceUrl
                  }
                  image2Large {
                    sourceUrl
                  }
                  image2Large2x {
                    sourceUrl
                  }
                }
                ... on NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_ItemHighlight_ItemHighlightFields_Video {
                  video {
                    mediaItemUrl
                  }
                  videoFallback {
                    mediaItemUrl
                  }
                }
              }
            }
            ... on NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_TextBlock {
              id
              title
              subtitle
              copy {
                paragraph
              }
              styledCopy {
                styledParagraph
              }
              categories
            }
          }
        }
      }
    }
  }
`;

export default CaseStudyTemplate;
