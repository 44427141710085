import React from 'react';
import PropTypes from 'prop-types';

import { Carousel } from './patterns/carousel';

export const CarouselBlock = ({ data }) => (
  <section
    className="carousel-block"
    style={{ backgroundColor: data.backgroundColor2 }}
  >
    {(data.title || data.subtitle) && (
      <div className="case-study__meta container">
        <div className="case-study__title">
          {data.title && <p>{data.title}</p>}
          {data.subtitle && <p>{data.subtitle}</p>}
        </div>
        <p className="case-study__description">{data.description}</p>
      </div>
    )}

    {data.categoriesPosition === 'top' && (
      <div className="container">
        <p className="carousel-block__categories top">{data.categories}</p>
      </div>
    )}

    <Carousel slides={data.slides} />

    {data.categories && data.categoriesPosition === 'bottom' && (
      <div className="container">
        <p className="carousel-block__categories">{data.categories}</p>
      </div>
    )}
  </section>
);

CarouselBlock.propTypes = {
  data: PropTypes.shape({
    backgroundColor2: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    categories: PropTypes.string,
    categoriesPosition: PropTypes.string,
    slides: PropTypes.arrayOf(
      PropTypes.shape({
        imgSmall: PropTypes.shape.isRequired,
        imgLarge: PropTypes.shape,
        imgAlt: PropTypes.shape.isRequired,
      })
    ),
  }).isRequired,
};
