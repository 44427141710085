import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Image } from './patterns/image';

export const TransitionImage = ({
  altText,
  index,
  src1Small,
  src1Small2x,
  src1Large,
  src1Large2x,
  src2Large,
  src2Large2x,
}) => {
  const [mqList, setMqlList] = useState();
  const [isLargeScreen, setIsLargeScreen] = useState(mqList && mqList.matches);

  const screenTest = e => {
    if (e.matches) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  };

  useEffect(() => {
    const mql = window.matchMedia('(min-width: 1200px)');

    setIsLargeScreen(
      Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      ) > 1200
    );

    setMqlList(mql);

    mql.addListener(screenTest);

    return () => {
      mql.removeListener(screenTest);
    };
  }, []);

  // No small image shown for second image to reduce data load on mobile devices.
  return (
    <div className="transition-image__wrapper">
      <Image
        alt={altText}
        className="transition-image__image transition-image--one"
        srcSmall={src1Small}
        srcSmall2x={src1Small2x && src1Small2x}
        srcLarge={src1Large}
        srcLarge2x={src1Large2x && src1Large2x}
        lazyLoad
      />
      {isLargeScreen && (
        <Image
          alt={altText}
          className="transition-image__image transition-image--two"
          index={index}
          srcLarge={src2Large}
          srcLarge2x={src2Large2x}
          lazyLoad
        />
      )}
    </div>
  );
};

TransitionImage.propTypes = {
  altText: PropTypes.string.isRequired,
  index: PropTypes.number,
  src1Small: PropTypes.string.isRequired,
  src1Small2x: PropTypes.string,
  src1Large: PropTypes.string.isRequired,
  src1Large2x: PropTypes.string,
  src2Large: PropTypes.string.isRequired,
  src2Large2x: PropTypes.string,
};

TransitionImage.defaultProps = {
  index: null,
  src1Small2x: '',
  src1Large2x: '',
  src2Large2x: '',
};
