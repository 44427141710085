import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Image } from './image';

import { IconArrow } from '../icons/arrow';

const PrevArrow = ({ currentSlide, onClick }) => (
  <button
    type="button"
    data-role="none"
    className="slick-prev slick-arrow"
    disabled={currentSlide === 0}
    aria-label="Previous"
    onClick={onClick}
  >
    <IconArrow />
  </button>
);

const NextArrow = ({ currentSlide, slideCount, onClick }) => (
  <button
    type="button"
    data-role="none"
    className="slick-next slick-arrow"
    disabled={currentSlide === slideCount - 1}
    aria-label="Next"
    onClick={onClick}
  >
    <IconArrow />
  </button>
);

export const Carousel = ({ slides }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <div className="carousel">
      <div className="carousel__list">
        <Slider {...settings}>
          {slides.map(slide => (
            <div key={slide.imageSmall.sourceUrl}>
              <Image
                alt={slide.altText}
                srcSmall={slide.imageSmall.sourceUrl}
                srcSmall2x={slide.imageSmall2x && slide.imageSmall2x.sourceUrl}
                srcLarge={slide.imageLarge.sourceUrl}
                srcLarge2x={slide.imageLarge2x && slide.imageLarge2x.sourceUrl}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

Carousel.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      imgAlt: PropTypes.string,
      imgSmall: PropTypes.string,
      imgLarge: PropTypes.string,
    })
  ).isRequired,
};

PrevArrow.propTypes = {
  currentSlide: PropTypes.number,
  onClick: PropTypes.func,
};

NextArrow.propTypes = {
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
  onClick: PropTypes.func,
};

PrevArrow.defaultProps = {
  currentSlide: null,
  onClick: () => {},
};

NextArrow.defaultProps = {
  currentSlide: null,
  slideCount: null,
  onClick: () => {},
};
