import React from 'react';
import PropTypes from 'prop-types';

import { Image } from './patterns/image';

export const OnThatNote = ({ anchor, fields }) => {
  const hiddenOnMobile = fields.map(field => field.hideOnMobile)[0];

  return (
    <section
      id={anchor}
      className={`case-study__on-that-note ${
        hiddenOnMobile ? 'large-screen-only' : ''
      }`}
    >
      <div className="container">
        {fields.map(field => {
          const typeName = field.__typename; // eslint-disable-line no-underscore-dangle

          switch (typeName) {
            case 'NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_OnThatNote_OnThatNoteFields_Image':
              return (
                <Image
                  alt={field.altText}
                  className={field.hideOnMobile ? 'large-screen-only' : ''}
                  srcSmall={field.imageSmall.sourceUrl}
                  srcSmall2x={
                    field.imageSmall2x && field.imageSmall2x.sourceUrl
                  }
                  srcLarge={field.imageLarge.sourceUrl}
                  srcLarge2x={
                    field.imageLarge2x && field.imageLarge2x.sourceUrl
                  }
                  lazyLoad
                  key={field.imageSmall.sourceUrl}
                  isSmall
                />
              );

            case 'NeueAmsterdam_CaseStudy_Casestudyfields_CaseStudySections_OnThatNote_OnThatNoteFields_TextBlock':
              return (
                <div
                  className={
                    field.hideOnMobile
                      ? 'case-study__on-that-note-text large-screen-only'
                      : 'case-study__on-that-note-text'
                  }
                  key={field.id}
                >
                  {(field.title || field.subtitle) && (
                    <div className="case-study__title">
                      {field.title && <p>{field.title}</p>}
                      {field.subtitle && <p>{field.subtitle}</p>}
                    </div>
                  )}
                  {field.copy &&
                    field.copy.map(paragraph => (
                      <p
                        className="case-study__description"
                        key={paragraph.paragraph}
                      >
                        {paragraph.paragraph}
                      </p>
                    ))}
                  {field.categoriesList && (
                    <p className="case-study__categories">
                      {field.categoriesList}
                    </p>
                  )}
                </div>
              );

            default:
              return null;
          }
        })}
      </div>
    </section>
  );
};

OnThatNote.propTypes = {
  anchor: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

OnThatNote.defaultProps = {
  anchor: '',
};
